<template>
  <v-skeleton-loader
    v-if="loading"
    type="article"
  /> 
</template>

<script lang="ts">
export default {
    name : "ModalLoader",
    props : {
        loading : { type: Boolean, required : true },
    },
}
</script>
